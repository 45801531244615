import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import {Link, useParams} from 'react-router-dom'
import {BackupSettings} from '../../../../applications-list/core/_models'
import {
  getBackupSettings,
  setScheduledBackupSettings,
} from '../../../../applications-list/core/_requests'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import {getAvailableStorages} from '../../../../../integrations/storages/storages-list/core/_requests'
import {Storage} from '../../../../../integrations/storages/storages-list/core/_models'

const BackupSettingsPage: React.FC = () => {
  const {applicationId} = useParams()
  const [remoteStorages, setRemoteStorages] = useState<Storage[]>([])
  const [initialSettings, setInitialSettings] = useState<BackupSettings>({
    status: false,
    scheduled_time: {
      hours: '00',
      minutes: '00',
    },
    frequency: 24,
    retention_period: 1,
    integrated_storage_id: '',
    start_now: false,
  })

  const hoursOptions = Array.from({length: 24}, (_, index) => ({
    value: index.toString().padStart(2, '0'),
    label: index.toString().padStart(2, '0'),
  }))

  const minutesOptions = [0, 10, 20, 30, 40, 50].map((minute) => ({
    value: minute.toString().padStart(2, '0'),
    label: minute.toString().padStart(2, '0'),
  }))

  const frequencyOptions = [
    {value: 1, label: 'Hourly'},
    {value: 24, label: 'Daily'},
    {value: 168, label: 'Weekly'},
    {value: 720, label: 'Monthly'},
  ]

  const retentionPeriodOptions = [
    {value: 1, label: '1 week'},
    {value: 2, label: '2 weeks'},
    {value: 3, label: '3 weeks'},
    {value: 4, label: '4 weeks'},
  ]

  const formik = useFormik<BackupSettings>({
    initialValues: initialSettings,
    enableReinitialize: true,
    onSubmit: (values) => {
      const backupSettings = {
        status: values.status,
        scheduled_time: values.scheduled_time,
        frequency: Number(values.frequency),
        retention_period: Number(values.retention_period),
        integrated_storage_id: values.integrated_storage_id,
        start_now: values.start_now ?? false,
      }

      setScheduledBackupSettings(applicationId ?? '', backupSettings)
        .then((response) => {
          if (response) {
            notifySuccess('Backup settings saved')

            setTimeout(() => {
              window.location.reload()
            }, 3000)
          } else {
            notifyError('Backup settings failed')
          }
        })
        .catch((error) => {
          notifyError('Backup settings failed: ' + error.message)
        })
    },
  })

  const getBackupSetting = async () => {
    try {
      const settings = await getBackupSettings(applicationId ?? '')
      if (settings) {
        setInitialSettings({...settings, start_now: false})

        formik.setValues({
          status: settings.status,
          scheduled_time: {
            hours: settings.scheduled_time.hours,
            minutes: settings.scheduled_time.minutes,
          },
          frequency: settings.frequency,
          retention_period: settings.retention_period,
          integrated_storage_id: settings.integrated_storage_id,
          start_now: false,
        })
      } else {
        formik.setFieldValue('status', false)
      }
    } catch (error) {
      formik.setFieldValue('status', false)
    }
  }

  const getStorageProviders = async () => {
    try {
      const providers = await getAvailableStorages()
      if (providers?.length) {
        setRemoteStorages(providers)
      } else {
        setRemoteStorages([])
      }
    } catch (error) {
      setRemoteStorages([])
    }
  }

  useEffect(() => {
    getBackupSetting()
    getStorageProviders()
  }, [])

  useEffect(() => {
    formik.setFieldValue(
      'integrated_storage_id',
      initialSettings.integrated_storage_id !== ''
        ? initialSettings.integrated_storage_id
        : remoteStorages[0]?.id
    )
  }, [initialSettings, remoteStorages])

  return (
    <div className='container-fluid'>
      <TabHeader heading='Schedule Backup' />
      <TabBody>
        <div className='text-primary'>Configure the settings to schedule your backup</div>
        <div className='separator border-secondary my-6'></div>
        <div className='mt-2'></div>
        {!remoteStorages.length ? (
          <div className='alert alert-warning d-flex align-items-center px-4 py-4 my-8'>
            <div className='d-flex flex-column'>
              <span>
                If you want to use Remote backups, please integrate your platform.{' '}
                <Link to='/integrations/storage/list'>Storage Integration</Link>.
              </span>
            </div>
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Row className='mb-5 align-items-center'>
              <Col sm='4'>
                <label className='form-label'>Status:</label>
              </Col>
              <Col sm='7'>
                <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                  <input
                    type='checkbox'
                    className='form-check-input w-45px h-30px'
                    checked={formik.values.status}
                    {...formik.getFieldProps('status')}
                    style={{
                      backgroundColor: formik.values.status ? 'green' : 'grey',
                      borderColor: formik.values.status ? 'green' : 'grey',
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className='mb-5 align-items-center'>
              <Col sm='4'>
                <label className='form-label'>Scheduled Time (UTC):</label>
              </Col>
              <Col sm='4'>
                <label className='form-label'>Hours:</label>
                <select
                  disabled={formik.values.status == false || formik.values.frequency == 1}
                  {...formik.getFieldProps('scheduled_time.hours')}
                  className='form-control'
                >
                  {hoursOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col sm='4'>
                <label className='form-label'>Minutes:</label>
                <select
                  disabled={formik.values.status == false || formik.values.frequency == 1}
                  {...formik.getFieldProps('scheduled_time.minutes')}
                  className='form-control'
                >
                  {minutesOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>

            <Row className='mb-5 align-items-center'>
              <Col sm='4'>
                <label className='form-label'>Frequency:</label>
              </Col>
              <Col sm='8'>
                <select
                  disabled={formik.values.status == false}
                  {...formik.getFieldProps('frequency')}
                  className='form-control'
                >
                  {frequencyOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>

            <Row className='mb-5 align-items-center'>
              <Col sm='4'>
                <label className='form-label'>Retention Period (in Weeks):</label>
              </Col>
              <Col sm='8'>
                <select
                  disabled={formik.values.status == false}
                  {...formik.getFieldProps('retention_period')}
                  className='form-control'
                >
                  {retentionPeriodOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>

            <Row className='mb-5 align-items-center'>
              <Col sm='4'>
                <label className='form-label'>Storage Provider:</label>
              </Col>
              <Col sm='8'>
                {remoteStorages.length > 0 ? (
                  <select
                    disabled={formik.values.status == false}
                    {...formik.getFieldProps('integrated_storage_id')}
                    className='form-control'
                  >
                    {remoteStorages.map((storageProviderElement) => (
                      <option
                        key={storageProviderElement.platform}
                        value={storageProviderElement.id}
                      >
                        {storageProviderElement.platform}
                      </option>
                    ))}
                  </select>
                ) : (
                  <label className='form-label'>No Storage Added</label>
                )}
              </Col>
            </Row>

            <Row className='mb-5 align-items-center'>
              <div className='form-group row align-items-center mb-3'>
                <div className='col-md-3 px-4 form-check form-check-custom form-check-solid'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    disabled={formik.values.status == false}
                    {...formik.getFieldProps('start_now')}
                  />
                  <label className='text-dark'>&nbsp;&nbsp;&nbsp;&nbsp;Start Now?</label>
                </div>
              </div>
            </Row>

            <Row className='mb-5 align-items-center'>
              <Col sm={{span: 10}}>
                <Button
                  variant='primary'
                  className='submit'
                  type='submit'
                  disabled={
                    (!initialSettings.status && initialSettings.status === formik.values.status) ||
                    (initialSettings.status && !formik.dirty)
                  }
                >
                  Save
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </TabBody>
    </div>
  )
}

export default BackupSettingsPage
