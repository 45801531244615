import {initialApplication, UpdateApplication, Application} from '../../core/_models'
import * as Yup from 'yup'
import {FC, useEffect, useState} from 'react'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {ApplicationsListLoading} from '../ApplicationsListLoading'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {getAllProjects} from '../../../../projects/projects-list/core/_requests'
import {Project} from '../../../../projects/projects-list/core/_models'
import {LaunchOption, Server, ServerState} from '../../../../servers/servers-list/core/_models'
import {getLaunchOptions, getAllServers} from '../../../../servers/servers-list/core/_requests'
import {createApplication, updateApplication} from '../../core/_requests'
import {notifyError, notifySuccess} from '../../../../../utils/NotifyUtils'

type Props = {
  isApplicationLoading: boolean
  application: Application
}

const launchApplicationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .matches(/^[a-zA-Z0-9_-]+$/, 'Only letters, numbers, underscores, and dashes are allowed')
    .required('Website name is required'),
  server_id: Yup.string().required('Server is required').notOneOf([''], 'Server is required'),
  launch_option_id: Yup.number()
    .required('Launch Option is required')
    .moreThan(0, 'Launch Option is required'),
  wordpress_title: Yup.string().required('WordPress Title is required'),
  wordpress_email: Yup.string().email('Wordpress Email should be a valid email address'),
  admin_username: Yup.string().required('Admin Username is required'),
  admin_password: Yup.string().required('Admin Password is required'),
})

const editApplicationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .matches(/^[a-zA-Z0-9_-]+$/, 'Only letters, numbers, underscores, and dashes are allowed')
    .required('Application name is required'),
})

const ApplicationEditModalForm: FC<Props> = ({application, isApplicationLoading}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [applicationForEdit] = useState<UpdateApplication>({
    application_id: application.application_id || initialApplication.application_id,
    name: application.name || initialApplication.name,
    project_id: application.project?.project_id || initialApplication.project_id,
  })

  const [launchOptions, setLaunchOptions] = useState<LaunchOption[]>()
  const [selectedLaunchOption, setSelectedLaunchOption] = useState<number>(0)

  const handleLaunchOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('launch_option_id', +event.target.value)
    setSelectedLaunchOption(parseInt(event.target.value))
  }

  const [projects, setProjects] = useState<Project[]>()
  const [selectedProject, setSelectedProject] = useState<string>(applicationForEdit.project_id)

  const handleProjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('project_id', event.target.value)
    setSelectedProject(event.target.value)
  }

  const [servers, setServers] = useState<Server[]>()
  const [selectedServer, setSelectedServer] = useState<string>('')

  const handleServerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('server_id', event.target.value)
    setSelectedServer(event.target.value)
  }

  useEffect(() => {
    getLaunchOptions().then((resp) => {
      setLaunchOptions(resp.data)
    })

    getAllProjects().then((resp) => {
      setProjects(resp.data)
    })

    getAllServers().then((resp) => {
      setServers(resp.data?.filter((server) => server.server_state === ServerState.STARTED))
    })
  }, [])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: applicationForEdit,
    validationSchema: itemIdForUpdate ? editApplicationSchema : launchApplicationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      if (values.wordpress_email != undefined && values.wordpress_email.length <= 0)
        delete values['wordpress_email']
      if (isNotEmpty(values.application_id)) {
        updateApplication(values.application_id, {
          project_id: values.project_id,
          name: values.name,
        })
          .then(() => {
            notifySuccess('Website update successful')
          })
          .catch((error) => {
            notifyError('Website update failed: ' + error.message)
          })
          .finally(() => {
            cancel(true)
          })
      } else {
        createApplication(values)
          .then(() => {
            notifySuccess('Website creation started')
          })
          .catch((error) => {
            notifyError('Website creation failed: ' + error.message)
          })
          .finally(() => {
            setTimeout(() => cancel(true), 1000)
          })
      }
      setSubmitting(false)
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_application_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_application_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_application_header'
          data-kt-scroll-wrappers='#kt_modal_add_application_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row w-100 mb-7'>
            {!itemIdForUpdate && (
              <div className='col-6'>
                <label className='required fw-bold fs-6 mb-2'>Server</label>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('server_id')}
                  name='server_id'
                  value={selectedServer}
                  onChange={handleServerChange}
                >
                  <option value=''>Select an option</option>
                  {servers?.map((option) => (
                    <option key={option.server_id} value={option.server_id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {formik.touched.server_id && formik.errors.server_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.server_id}</div>
                  </div>
                )}
              </div>
            )}
            {!itemIdForUpdate && (
              <div className='col-6'>
                <label className='required fw-bold fs-6 mb-2'>Launch Options</label>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('launch_option_id')}
                  name='launch_option_id'
                  value={selectedLaunchOption}
                  onChange={handleLaunchOptionChange}
                >
                  <option value='0'>Select an option</option>
                  {launchOptions?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.launch_option_platform} - {option.launch_option_type}
                    </option>
                  ))}
                </select>
                {formik.touched.launch_option_id && formik.errors.launch_option_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.launch_option_id}</div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='row w-100 mb-7'>
            <div className='col-6'>
              <label className='required fw-bold fs-6 mb-2'>Website Name</label>
              <input
                placeholder='Website Name'
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isApplicationLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='fw-bold fs-6 mb-2'>Project</label>
              <select
                className='form-select form-select-solid form-select-lg'
                {...formik.getFieldProps('project')}
                name='project_id'
                value={selectedProject ?? ''}
                onChange={handleProjectChange}
              >
                <option value=''>Select an option</option>
                {projects?.map((option) => (
                  <option key={option.project_id} value={option.project_id?.toString()}>
                    {option.name}
                  </option>
                ))}
              </select>
              {formik.touched.project_id && formik.errors.project_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.project_id}</div>
                </div>
              )}
            </div>
          </div>

          {!itemIdForUpdate && (
            <>
              <hr className='my-5'></hr>

              <div className='row w-100 mb-7'>
                <div className='col-6'>
                  <label className='required fw-bold fs-6 mb-2'>WordPress Title</label>
                  <input
                    placeholder='WordPress Title'
                    {...formik.getFieldProps('wordpress_title')}
                    type='text'
                    name='wordpress_title'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid':
                          formik.touched.wordpress_title && formik.errors.wordpress_title,
                      },
                      {
                        'is-valid':
                          formik.touched.wordpress_title && !formik.errors.wordpress_title,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isApplicationLoading}
                  />
                  {formik.touched.wordpress_title && formik.errors.wordpress_title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.wordpress_title}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-6'>
                  <label className='fw-bold fs-6 mb-2'>WordPress Email (Optional)</label>
                  <input
                    placeholder='WordPress Email'
                    {...formik.getFieldProps('wordpress_email')}
                    type='email'
                    name='wordpress_email'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid':
                          formik.touched.wordpress_email && formik.errors.wordpress_email,
                      },
                      {
                        'is-valid':
                          formik.touched.wordpress_email && !formik.errors.wordpress_email,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isApplicationLoading}
                  />
                  {formik.touched.wordpress_email && formik.errors.wordpress_email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.wordpress_email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row w-100 mb-7'>
                <div className='col-6'>
                  <label className='required fw-bold fs-6 mb-2'>Admin Username</label>
                  <input
                    placeholder='Admin Username'
                    {...formik.getFieldProps('admin_username')}
                    type='text'
                    name='admin_username'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.admin_username && formik.errors.admin_username},
                      {
                        'is-valid': formik.touched.admin_username && !formik.errors.admin_username,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isApplicationLoading}
                  />
                  {formik.touched.admin_username && formik.errors.admin_username && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.admin_username}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-6'>
                  <label className='required fw-bold fs-6 mb-2'>Admin Password</label>
                  <input
                    placeholder='Admin Password'
                    {...formik.getFieldProps('admin_password')}
                    type='password'
                    name='admin_password'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.admin_password && formik.errors.admin_password},
                      {
                        'is-valid': formik.touched.admin_password && !formik.errors.admin_password,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isApplicationLoading}
                  />
                  {formik.touched.admin_password && formik.errors.admin_password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.admin_password}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-applications-modal-action='cancel'
            disabled={formik.isSubmitting || isApplicationLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-applications-modal-action='submit'
            disabled={
              isApplicationLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isApplicationLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isApplicationLoading) && <ApplicationsListLoading />}
    </>
  )
}

export {ApplicationEditModalForm}
