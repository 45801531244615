import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import FeatureToggle from '../../../../../common/components/FeatureToggle'
import FeatureToggleHandler from '../../../../../../utils/FeatureToggleHandler'
import {
  getJSLoadingMethod,
  getJSMinificationState,
  purgeJSMinificationCache,
  updateJSLoadingMethod,
  updateJSMinificationState,
} from '../../../../applications-list/core/_requests'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../../utils/NotifyUtils'
import ConfirmDeleteModal from '../../../../../../utils/ConfirmDeleteModal'

const JS = () => {
  const {applicationId} = useParams()

  const [jsMinify, setJsMinify] = useState<boolean>(false)
  const [jsMinifyLoading, setJsMinifyLoading] = useState<boolean>(false)
  const [jsRenderLoading, setJsRenderLoading] = useState(false)

  const [showPurgeConfirmation, setShowPurgeConfirmation] = useState(false)
  const [jsLoadingMethod, setJsLoadingMethod] = useState<string>()

  const fetchMinifyStatus = async () => {
    try {
      const [jsMinifyResponse, jsLoadingResponse] = await Promise.all([
        getJSMinificationState(applicationId!),
        getJSLoadingMethod(applicationId!),
      ])

      setJsMinify(jsMinifyResponse.state)
      setJsLoadingMethod(jsLoadingResponse.method)
    } catch (error) {
      notifyError('Fetch JS minify state failed!')
    }
  }

  const handlePurgeJSCache = () => {
    setShowPurgeConfirmation(true)
  }

  const handleConfirmDelete = () => {
    const toastId = notifyProgress('Cache purge in progess...')
    const success = purgeJSMinificationCache(applicationId!)
      .then((data) => {
        notifySuccess('Cache purge successful!', toastId)
      })
      .catch((error) => {
        notifyError('Cache purge failed!', toastId)
      })
      .finally(() => {
        setShowPurgeConfirmation(false)
      })
  }

  const handleSettingChange = (changedValue: string) => {
    setJsRenderLoading(true)
    updateJSLoadingMethod(applicationId!, changedValue)
      .then((resp) => {
        setJsLoadingMethod(changedValue)
        notifySuccess('JS loading update successful!')
      })
      .catch((error) => {
        notifyError('JS loading update failed!')
      })
      .finally(() => setJsRenderLoading(false))
  }

  useEffect(() => {
    fetchMinifyStatus()
  }, [applicationId])

  return (
    <div className='container-fluid'>
      <TabHeader heading='JS Optimizations' />
      <TabBody>
        <div className='d-flex flex-column'>
          <FeatureToggle
            title='Minification'
            description='Minifies JS files removing unncessary characters and reducing file size.'
            enabled={jsMinify}
            onToggle={() =>
              FeatureToggleHandler(
                applicationId!,
                jsMinify,
                updateJSMinificationState,
                setJsMinify,
                setJsMinifyLoading,
                `JS minification ${jsMinify ? 'disabled' : 'enabled'} successfully`,
                'Toggling JS minification failed'
              )
            }
            loading={jsMinifyLoading}
          />
          <div className='rounded-3 border p-4 mb-4 d-flex'>
            <div className='me-4 flex-grow-1' style={{flex: '1'}}>
              <h5 className='fw-bolder d-flex align-items-center'>Purge Cache</h5>
              <p className='text-muted'>
                Manually purge cached content to ensure the latest version is served to visitors.
              </p>
            </div>
            <div
              className='d-flex border-start flex-column justify-content-center align-items-center'
              style={{flex: '0 0 20%'}}
            >
              <div className='d-flex flex-column'>
                <button
                  className='btn btn-primary btn-sm p-3'
                  onClick={handlePurgeJSCache}
                  disabled={!jsMinify}
                >
                  Purge Cache
                </button>
              </div>
            </div>
          </div>
          <div className='rounded-3 border p-4 mb-4 d-flex'>
            <div className='me-4 flex-grow-1' style={{flex: '1'}}>
              <h5 className='fw-bolder d-flex align-items-center'>JS Loading</h5>
              <p className='text-muted'>JS loading pattern, default, async or defer.</p>
            </div>
            <div
              className='d-flex border-start flex-column justify-content-center align-items-center'
              style={{flex: '0 0 20%'}}
            >
              <div className='form-check mt-2 d-flex flex-row align-items-center'>
                <div>
                  {[
                    ['Default', 'default'],
                    ['Async', 'async'],
                    ['Defer', 'defer'],
                  ].map((option) => {
                    return (
                      <div className='form-check-label mb-4'>
                        <input
                          type='radio'
                          className='form-check-input'
                          checked={jsLoadingMethod == option[1]}
                          onChange={() => handleSettingChange(option[1])}
                        />
                        {option[0]}
                      </div>
                    )
                  })}
                </div>
                <div className='ms-3' style={{width: '20px'}}>
                  {jsRenderLoading && (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {showPurgeConfirmation && (
            <ConfirmDeleteModal
              setIsOpen={setShowPurgeConfirmation}
              title='Are you sure?'
              content='This will purge the complete cache and might degrade the performance for some time'
              deleteFunction={handleConfirmDelete}
            />
          )}
        </div>
      </TabBody>
    </div>
  )
}

export default JS
