import {
  initialServer,
  Server,
  LaunchOption,
  InstanceOptionDetails,
  InstanceOptionRegions,
  ServerDto,
  PlanType,
  CloudProvider,
} from '../../core/_models'
import * as Yup from 'yup'
import {FC, SetStateAction, useEffect, useState} from 'react'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import {ServersListLoading} from '../ServersListLoading'
import {getInstanceOptions, getLaunchOptions, updateServer} from '../../core/_requests'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {getAllProjects} from '../../../../projects/projects-list/core/_requests'
import {Project} from '../../../../projects/projects-list/core/_models'
import Step2 from './steps/Step2'
import Step1 from './steps/Step1'
import {notifyError, notifySuccess} from '../../../../../utils/NotifyUtils'

type Props = {
  isServerLoading: boolean
  server: Server
}

const editServerSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .matches(/^[a-zA-Z0-9_-]+$/, 'Only letters, numbers, underscores, and dashes are allowed')
    .required('Server name is required'),
})

const launchServerSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .matches(/^[a-zA-Z0-9_-]+$/, 'Only letters, numbers, underscores, and dashes are allowed')
    .required('Server name is required'),
  is_bare_metal: Yup.boolean(),
  instance_option_id: Yup.number().when('cloud_provider', {
    is: (value: string) => value != CloudProvider.CUSTOM,
    then: Yup.number()
      .required('Instance option is required')
      .min(1, 'Please select a valid instance option'),
  }),
  region: Yup.string().when('cloud_provider', {
    is: (value: string) => value != CloudProvider.CUSTOM,
    then: Yup.string().required('Region is required').min(1, 'Please select a valid region'),
  }),
  ip: Yup.string().when('cloud_provider', {
    is: CloudProvider.CUSTOM,
    then: Yup.string()
      .matches(
        /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/,
        'Invalid IPv4 address'
      )
      .required('IP address is required'),
  }),
  root_password: Yup.string().when('cloud_provider', {
    is: CloudProvider.CUSTOM,
    then: Yup.string().required('Root password is required'),
  }),
  ssh_port: Yup.number().when('cloud_provider', {
    is: CloudProvider.CUSTOM,
    then: Yup.number()
      .min(1, 'Not a valid port')
      .max(65535, 'Not a valid port')
      .required('SSH port is required'),
  }),
  application_name: Yup.string().when('is_bare_metal', {
    is: false,
    then: Yup.string().required('Application name is required'),
  }),
  launch_option_id: Yup.number().when('is_bare_metal', {
    is: false,
    then: Yup.number().min(1, 'Please select a valid launch option'),
  }),
  wordpress_title: Yup.string().when('is_bare_metal', {
    is: false,
    then: Yup.string().required('WordPress Title is required'),
  }),
  wordpress_email: Yup.string().email('Wordpress Email should be a valid email address'),
  admin_username: Yup.string().when('is_bare_metal', {
    is: false,
    then: Yup.string().required('Admin Username is required'),
  }),
  admin_password: Yup.string().when('is_bare_metal', {
    is: false,
    then: Yup.string().required('Admin Password is required'),
  }),
})

const ServerEditModalForm: FC<Props> = ({server, isServerLoading}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const serverForEdit: ServerDto & {is_bare_metal: boolean} = {
    server_id: server.server_id ?? initialServer.server_id,
    launch_option_id: initialServer.launch_option_id,
    application_name: itemIdForUpdate ? 'App Name' : '',
    name: server.name || initialServer.name,
    instance_option_id: initialServer.instance_option_id,
    region: '',
    storage: 30,
    project_id: '',
    plan_type: PlanType.MONTHLY,
    country: 'INDIA',
    ip: '',
    root_password: '',
    ssh_port: 22,
    wordpress_title: initialServer.wordpress_title,
    wordpress_email: initialServer.wordpress_email,
    admin_username: initialServer.admin_username,
    admin_password: initialServer.admin_password,
    is_bare_metal: false,
  }

  const [step, setStep] = useState(1)

  const [launchOptions, setLaunchOptions] = useState<LaunchOption[]>()
  const [selectedLaunchOption, setSelectedLaunchOption] = useState<number>()

  const handleLaunchOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('launch_option_id', +event.target.value)
    setSelectedLaunchOption(parseInt(event.target.value))
  }

  const [projects, setProjects] = useState<Project[]>()
  const [selectedProject, setSelectedProject] = useState<string>()

  const handleProjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('project_id', event.target.value)
    setSelectedProject(event.target.value)
  }

  const [instanceOptionsData, setInstanceOptionsData] = useState<{
    [x: string]: SetStateAction<InstanceOptionDetails | undefined>
  }>()

  const [instanceOptionsRegions, setInstanceOptionsRegions] = useState<{
    [x: string]: SetStateAction<InstanceOptionRegions | undefined>
  }>()

  const [externalCloudStatus, setExternalCloudStatus] = useState<{
    [x: string]: {has_external_cloud: boolean}
  }>()

  const [cloudProviders, setCloudProviders] = useState<string[]>()
  const [selectedCloudProvider, setSelectedCloudProvider] = useState<string>()

  const [hasExternalCloud, setHasExternalCloud] = useState(false)

  const [isExternalCloud, setIsExternalCloud] = useState(false)
  const toggleIsExternalCloud = () => {
    setIsExternalCloud((prev) => !prev)
  }

  const handleCloudProviderChange = (
    event: React.MouseEvent<HTMLImageElement>,
    provider: string
  ) => {
    formik.setFieldValue('cloud_provider', provider)
    setSelectedCloudProvider(provider)

    if (provider === CloudProvider.CUSTOM) {
      setHasExternalCloud(false)
      setIsExternalCloud(true)
    } else {
      setInstanceOptions(instanceOptionsData && instanceOptionsData[provider])
      setRegions(instanceOptionsRegions && instanceOptionsRegions[provider])
      setHasExternalCloud(externalCloudStatus![provider].has_external_cloud ?? false)
      setIsExternalCloud(false)
      setSelectedInstanceOption('')
      setSelectedRegion('')
    }
    formik.setFieldValue('instance_option_id', 0)
    formik.setFieldValue('region', '')
    formik.setFieldValue('ip', '')
    formik.setFieldValue('root_password', '')
  }

  const [instanceOptions, setInstanceOptions] = useState<InstanceOptionDetails>()
  const [selectedInstanceOption, setSelectedInstanceOption] = useState<string>()

  const handleInstanceOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('instance_option_id', +event.target.value)
    setSelectedInstanceOption(event.target.value)
  }

  const [regions, setRegions] = useState<InstanceOptionRegions>()
  const [selectedRegion, setSelectedRegion] = useState<string>('')

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('region', event.target.value)
    setSelectedRegion(event.target.value)
  }

  const [selectedPlan, setSelectedPlan] = useState<PlanType>(PlanType.MONTHLY)

  const handlePlanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('plan_type', event.target.value)
    setSelectedPlan(event.target.value as PlanType)
  }

  const [isBareMetal, setIsBareMetal] = useState(false)
  const toggleIsBareMetal = () => {
    setIsBareMetal((prev) => {
      formik.setFieldValue('is_bare_metal', !prev)
      return !prev
    })
  }

  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    setIsFormValid(!Boolean(selectedCloudProvider && selectedPlan))
  }, [selectedCloudProvider, selectedPlan])

  useEffect(() => {
    getLaunchOptions().then((resp) => {
      setLaunchOptions(resp.data)
    })

    getAllProjects().then((resp) => {
      setProjects(resp.data)
    })

    getInstanceOptions().then((resp) => {
      setInstanceOptionsData(resp.data)
      setInstanceOptionsRegions(resp.data)
      setCloudProviders(Object.keys(resp.data))
      setExternalCloudStatus(resp.data)
    })
  }, [])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: serverForEdit,
    validationSchema: itemIdForUpdate ? editServerSchema : launchServerSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      if (isNotEmpty(values.server_id)) {
        updateServer(values)
          .then((data) => {
            notifySuccess('Server updation successful')
          })
          .catch((error) => {
            notifyError(`Server updation failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_server_form' className='form' onSubmit={formik.handleSubmit}>
        {step == 1 ? (
          <Step1
            formik={formik}
            handleCloudProviderChange={handleCloudProviderChange}
            handleInstanceOptionChange={handleInstanceOptionChange}
            handleRegionChange={handleRegionChange}
            regions={regions}
            instanceOptions={instanceOptions}
            cloudProviders={cloudProviders}
            selectedCloudProvider={selectedCloudProvider}
            selectedInstanceOption={selectedInstanceOption}
            selectedRegion={selectedRegion}
            itemIdForUpdate={itemIdForUpdate}
            isServerLoading={isServerLoading}
            setStep={setStep}
            selectedPlan={selectedPlan}
            handlePlanChange={handlePlanChange}
            hasExternalCloud={hasExternalCloud}
            isExternalCloud={isExternalCloud}
            toggleIsExternalCloud={toggleIsExternalCloud}
          />
        ) : (
          <Step2
            formik={formik}
            itemIdForUpdate={itemIdForUpdate}
            isServerLoading={isServerLoading}
            handleLaunchOptionChange={handleLaunchOptionChange}
            handleProjectChange={handleProjectChange}
            launchOptions={launchOptions}
            projects={projects}
            selectedLaunchOption={selectedLaunchOption}
            selectedProject={selectedProject}
            setStep={setStep}
            isBareMetal={isBareMetal}
            toggleIsBareMetal={toggleIsBareMetal}
            isExternalCloud={isExternalCloud}
            isFormValid={isFormValid}
          />
        )}
      </form>
      {(formik.isSubmitting || isServerLoading) && <ServersListLoading />}
    </>
  )
}

export {ServerEditModalForm}
