import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {
  getSubscriptionByServerId,
  renewSubscription,
  updateSubscriptionAutoRenewSetting,
} from '../../../../../payments/subscriptions/subscriptions-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import ConfirmSuccessModal from '../../../../../../utils/ConfirmSuccessModal'
import {Subscription} from '../../../../../payments/subscriptions/subscriptions-list/core/_models'
import {CurrencySymbol} from '../../../../servers-list/core/_models'
import FeatureToggle from '../../../../../common/components/FeatureToggle'

const Renew = () => {
  const {serverId} = useParams<{serverId: string}>()

  const [subscription, setSubscription] = useState<Subscription | null>(null)
  const [showPopup, setShowPopup] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [autoRenew, setAutoRenew] = useState<boolean>(true)
  const [autoRenewLoading, setAutoRenewLoading] = useState<boolean>(false)

  useEffect(() => {
    getSubscriptionByServerId(serverId!)
      .then((data) => {
        if (data) {
          setSubscription(data)
          setAutoRenew(data.auto_renew ?? true)
        } else {
          setError('Failed to load subscription details')
        }
      })
      .catch((err) => {
        setError('Failed to load subscription details')
        console.error(err)
      })
  }, [serverId])

  const handleRenew = async () => {
    try {
      const result = await renewSubscription(serverId!)
      if (result) {
        notifySuccess('Subscription renewed successfully!')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notifyError('Failed to renew subscription')
      }
    } catch (error) {
      notifyError(String(error))
    } finally {
      setShowPopup(false)
    }
  }

  const handleToggle = async () => {
    setAutoRenewLoading(true)

    try {
      const newState = !autoRenew
      const success = await updateSubscriptionAutoRenewSetting(serverId, newState)

      if (success) {
        setAutoRenew(newState)
        notifySuccess('Auto-renew setting updated!')
      } else {
        notifyError('Auto-renew setting update failed!')
      }
    } catch (error) {
      notifyError('Auto-renew setting update failed!')
    } finally {
      setAutoRenewLoading(false)
    }
  }

  return (
    <div className='container-fluid' id='renew_subscription_tab'>
      <TabHeader heading='Renew Subscription' />
      <TabBody>
        <div className='mb-5'>
          {subscription ? (
            <div>
              <FeatureToggle
                title='Auto-Renew?'
                description='Please make sure that enough credit is available to allow renewal.'
                enabled={autoRenew}
                onToggle={() => handleToggle()}
                loading={autoRenewLoading}
              />
              <div style={{paddingLeft: '12px'}}>
                <table
                  id='kt_table_subscriptions'
                  className='table align-middle table-row-dashed fs-6 gy-2 dataTable no-footer'
                  role='table'
                  style={{margin: '0', padding: '0'}}
                >
                  <tbody className='text-gray-600 fw-bold' role='rowgroup'>
                    {[
                      {label: 'Product Type', value: subscription.product_type},
                      {label: 'Amount', value: `${CurrencySymbol.USD}${subscription.amount}`},
                      {label: 'Status', value: subscription.status},
                      {label: 'Renewal Date', value: subscription.renewal_date},
                      {label: 'Billing Frequency', value: subscription.billing_frequency},
                      {label: 'End Date', value: subscription.end_date},
                    ].map((item, index) => (
                      <tr key={index} role='row'>
                        <td role='cell' className='p-0' style={{height: '35px', padding: '6px'}}>
                          <div className='d-flex align-items-center'>
                            <div style={{marginRight: '6px'}} className='d-flex flex-column'>
                              {item.label}:
                            </div>
                            <span>
                              {item.value}
                              {item.label === 'Amount' && (
                                <div className='d-inline'> + 18% GST</div>
                              )}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <button className='btn btn-success mt-4' onClick={() => setShowPopup(true)}>
                  Renew Now
                </button>
              </div>
            </div>
          ) : (
            <div className='alert alert-warning'>No subscription details available.</div>
          )}
        </div>

        {showPopup && (
          <ConfirmSuccessModal
            title='Confirm Renewal'
            btnTitle='Renew'
            content='Renew your subscription to continue enjoying all the benefits.'
            successFunction={handleRenew}
            setIsOpen={setShowPopup}
          />
        )}

        {error && <div className='alert alert-danger mt-3'>{error}</div>}
      </TabBody>
    </div>
  )
}

export default Renew
