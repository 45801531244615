import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import Uptime from './components/Uptime'
import Newrelic from './components/Newrelic'
import AccessLogs from './components/AccessLogs'
import ErrorLogs from './components/ErrorLogs'
import TabList from '../../../../../utils/TabList'

const Monitoring = () => {
  const {applicationId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <>
            <div className='card mb-5 mb-xl-10' id='dc_monitoring_view'>
              <div className='d-flex overflow-auto'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Monitoring</h3>
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <div className='py-5 px-9'>
                  <div className='card-title m-0'>
                    <h5 className='fw-bolder mb-3'>Logs</h5>
                  </div>
                  <TabList
                    tabs={[
                      {
                        name: 'Access Logs',
                        path: `/application/${applicationId}/monitoring-logs/access`,
                      },
                      {
                        name: 'Error Logs',
                        path: `/application/${applicationId}/monitoring-logs/error`,
                      },
                    ]}
                  />
                  <div className='separator border-secondary my-4'></div>
                  <div className='card-title m-0'>
                    <h5 className='fw-bolder mb-3'>Monitoring</h5>
                  </div>
                  <TabList
                    tabs={[
                      {
                        name: 'Uptime',
                        path: `/application/${applicationId}/monitoring-logs/uptime`,
                      },
                      {
                        name: 'Newrelic',
                        path: `/application/${applicationId}/monitoring-logs/newrelic`,
                      },
                    ]}
                  />
                </div>
                <Outlet />
              </div>
            </div>
          </>
        }
      >
        <Route path='uptime' element={<Uptime />} />
        <Route path='newrelic' element={<Newrelic />} />
        <Route path='access' element={<AccessLogs />} />
        <Route path='error' element={<ErrorLogs />} />
        <Route index element={<Navigate to='access' />} />
      </Route>
    </Routes>
  )
}

export default Monitoring
