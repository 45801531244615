import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ProjectsPage from '../modules/projects/ProjectsPage'
import ServersPage from '../modules/servers/ServersPage'
import ApplicationsPage from '../modules/applications/ApplicationsPage'
import ServerPage from '../modules/servers/ServerPage'
import ApplicationPage from '../modules/applications/ApplicationPage'
import DomainsPage from '../modules/dns/DomainsPage'
import DomainPage from '../modules/dns/DomainPage'
import CloudsPage from '../modules/integrations/clouds/CloudsPage'
import StoragesPage from '../modules/integrations/storages/StoragesPage'
import GitsPage from '../modules/integrations/gits/GitsPage'
import CloudflarePage from '../modules/integrations/cloudflare/CloudflarePage'
import CloudflareDomainPage from '../modules/integrations/cloudflare/DomainPage'
import NewrelicPage from '../modules/integrations/observability/newrelic/NewrelicPage'
import Alerting from '../modules/servers/server-settings/components/monitoring/components/Alerts'
import ToolsPage from '../modules/tools/Tools'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/servers' />} />
        {/* Lazy Modules */}
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='projects/*'
          element={
            <SuspensedView>
              <ProjectsPage />
            </SuspensedView>
          }
        />
        <Route
          path='alerting/*'
          element={
            <SuspensedView>
              <Alerting />
            </SuspensedView>
          }
        />
        <Route
          path='servers/*'
          element={
            <SuspensedView>
              <ServersPage />
            </SuspensedView>
          }
        />
        <Route
          path='server/*'
          element={
            <SuspensedView>
              <ServerPage />
            </SuspensedView>
          }
        />
        <Route
          path='applications/*'
          element={
            <SuspensedView>
              <ApplicationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='application/*'
          element={
            <SuspensedView>
              <ApplicationPage />
            </SuspensedView>
          }
        />
        <Route
          path='integrations/cloud/*'
          element={
            <SuspensedView>
              <CloudsPage />
            </SuspensedView>
          }
        />
        <Route
          path='integrations/storage/*'
          element={
            <SuspensedView>
              <StoragesPage />
            </SuspensedView>
          }
        />
        <Route
          path='integrations/git/*'
          element={
            <SuspensedView>
              <GitsPage />
            </SuspensedView>
          }
        />
        <Route
          path='tools/*'
          element={
            <SuspensedView>
              <ToolsPage />
            </SuspensedView>
          }
        />
        <Route
          path='domains/*'
          element={
            <SuspensedView>
              <DomainsPage />
            </SuspensedView>
          }
        />
        <Route
          path='domain/*'
          element={
            <SuspensedView>
              <DomainPage />
            </SuspensedView>
          }
        />
        <Route
          path='integrations/cloudflare/domain/*'
          element={
            <SuspensedView>
              <CloudflareDomainPage />
            </SuspensedView>
          }
        />
        <Route
          path='integrations/cloudflare/*'
          element={
            <SuspensedView>
              <CloudflarePage />
            </SuspensedView>
          }
        />
        <Route
          path='integrations/newrelic/*'
          element={
            <SuspensedView>
              <NewrelicPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
