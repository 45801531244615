import {ApexOptions} from 'apexcharts'
import Chart from 'react-apexcharts'
import {TTFBInfo} from '../core/_models'

const TimeChart = ({info}: {info: TTFBInfo}) => {
  const config = {
    series: [
      {
        data: [
          {
            x: `DNS`,
            y: [0, info.dns],
          },
          {
            x: 'TCP',
            y: [info.dns, info.tcp],
          },
          {
            x: 'SSL',
            y: [info.tcp, info.tls],
          },
          {
            x: 'Server Response',
            y: [info.tls, info.resStart],
          },
          {
            x: 'Download',
            y: [info.resStart, info.resEnd],
          },
          {
            x: 'Close',
            y: [info.resEnd, info.close],
          },
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'rangeBar',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any, opts: any) {
          const customLabels = [
            `~${info.dns.toFixed(2)} ms`,
            `~${(info.tcp - info.dns).toFixed(2)} ms`,
            `~${(info.tls - info.tcp).toFixed(2)} ms`,
            `~${(info.resStart - info.tls).toFixed(2)} ms`,
            `~${(info.resEnd - info.resStart).toFixed(2)} ms`,
            `~${(info.close - info.resEnd).toFixed(2)} ms`,
          ]
          return customLabels[opts.dataPointIndex] // Custom label based on index
        },
        style: {
          colors: ['#fff'],
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 5,
          distributed: true,
        },
      },
      xaxis: {
        type: 'numeric',
        title: {
          text: 'Milliseconds',
        },
        labels: {
          formatter: function (value: any) {
            return value + ' ms'
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  }
  return (
    <Chart
      options={config.options as ApexOptions}
      series={config.series}
      type='rangeBar'
      height={320}
    />
  )
}

export default TimeChart
