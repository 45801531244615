import {Link} from 'react-router-dom'
import {TabProps} from './TabProps'

const TabList = ({tabs}: TabProps) => {
  return (
    <ul className='nav nav-tabs nav-pills border-0 flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6'>
      {tabs.map((tab) => {
        return (
          <li className='nav-item w-md-200px me-0' key={tab.name}>
            <Link
              to={tab.path}
              className={`nav-link ` + (window.location.pathname === tab.path && 'active')}
            >
              {tab.name}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default TabList
