import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {HTMLMinifyObject} from '../../../../applications-list/core/_models'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {
  getHTMLMinifyStateByApplication,
  updateApplicationMinifyHTMLState,
} from '../../../../applications-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const HTMLMinify = () => {
  const {applicationId} = useParams()

  const [htmlMinifyInfo, setHTMLMinifyInfo] = useState<HTMLMinifyObject | undefined>({
    state: false,
  })

  const [isUpdating, setIsUpdating] = useState(false)

  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)

  useEffect(() => {
    getHTMLMinifyStateByApplication(applicationId ?? '').then((resp) => {
      setHTMLMinifyInfo(resp)
    })
  }, [])

  const handleToggleChange = () => {
    setHTMLMinifyInfo({
      ...htmlMinifyInfo!,
      state: !htmlMinifyInfo!.state,
    })

    setIsSaveButtonActive(!isSaveButtonActive)
  }

  const handleSaveButtonClick = () => {
    setIsUpdating(true)

    updateApplicationMinifyHTMLState(applicationId ?? '', htmlMinifyInfo!.state).then(
      (response) => {
        if (response) {
          notifySuccess('HTML minify is updated')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          notifyError('Something went wrong')
        }
        setIsUpdating(false)
        setIsSaveButtonActive(false)
      }
    )
  }

  return (
    <div className='container-fluid' id='html_minify_tab'>
      <TabHeader heading='HTML Optimizations' />
      <TabBody>
        <div className='row mb-10'>
          <div className='col-lg-12 mt-3'>
            <div className='row align-items-center'>
              <div className='col-lg-2'>HTML Minify:</div>
              <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                <input
                  className='form-check-input w-45px h-25px'
                  type='checkbox'
                  checked={htmlMinifyInfo!.state}
                  onChange={handleToggleChange}
                  style={{
                    backgroundColor: htmlMinifyInfo!.state ? 'green' : 'grey',
                    borderColor: htmlMinifyInfo!.state ? 'green' : 'grey',
                  }}
                />
              </div>
              <div className='col-lg-6'>
                <button
                  type='button'
                  className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10`}
                  onClick={handleSaveButtonClick}
                  disabled={!isSaveButtonActive || isUpdating}
                >
                  <span className='indicator-label'>
                    {isUpdating ? 'Please wait . . .' : 'Save'}
                  </span>
                  {isUpdating && (
                    <span className='indicator-progress'>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </TabBody>
    </div>
  )
}

export default HTMLMinify
