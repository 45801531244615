import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import FeatureToggle from '../../../../../common/components/FeatureToggle'
import FeatureToggleHandler from '../../../../../../utils/FeatureToggleHandler'
import {
  getCSSMinificationState,
  purgeCSSMinificationCache,
  updateCSSMinificationState,
} from '../../../../applications-list/core/_requests'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../../utils/NotifyUtils'
import ConfirmDeleteModal from '../../../../../../utils/ConfirmDeleteModal'

const CSS = () => {
  const {applicationId} = useParams()

  const [cssMinify, setCssMinify] = useState<boolean>(false)
  const [cssMinifyLoading, setCssMinifyLoading] = useState<boolean>(false)
  const [showPurgeConfirmation, setShowPurgeConfirmation] = useState(false)

  const fetchMinifyStatus = async () => {
    try {
      const response = await getCSSMinificationState(applicationId!)
      setCssMinify(response.state)
    } catch (error) {
      notifyError('Fetch CSS minify state failed!')
    }
  }

  const handlePurgeCSSCache = () => {
    setShowPurgeConfirmation(true)
  }

  const handleConfirmDelete = () => {
    const toastId = notifyProgress('Cache purge in progess...')
    const success = purgeCSSMinificationCache(applicationId!)
      .then((data) => {
        notifySuccess('Cache purge successful!', toastId)
      })
      .catch((error) => {
        notifyError('Cache purge failed!', toastId)
      })
      .finally(() => {
        setShowPurgeConfirmation(false)
      })
  }

  useEffect(() => {
    fetchMinifyStatus()
  }, [applicationId])

  return (
    <div className='container-fluid'>
      <TabHeader heading='CSS Optimizations' />
      <TabBody>
        <div className='d-flex flex-column'>
          <FeatureToggle
            title='Minification'
            description='Minifies CSS files removing unncessary characters and reducing file size.'
            enabled={cssMinify}
            onToggle={() =>
              FeatureToggleHandler(
                applicationId!,
                cssMinify,
                updateCSSMinificationState,
                setCssMinify,
                setCssMinifyLoading,
                `CSS minification ${cssMinify ? 'disabled' : 'enabled'} successfully`,
                'Toggling CSS minification failed'
              )
            }
            loading={cssMinifyLoading}
          />
          <div className='rounded-3 border p-4 mb-4 d-flex'>
            <div className='me-4 flex-grow-1' style={{flex: '1'}}>
              <h5 className='fw-bolder d-flex align-items-center'>Purge Cache</h5>
              <p className='text-muted'>
                Manually purge cached content to ensure the latest version is served to visitors.
              </p>
            </div>
            <div
              className='d-flex border-start flex-column justify-content-center align-items-center'
              style={{flex: '0 0 20%'}}
            >
              <div className='d-flex flex-column'>
                <button
                  className='btn btn-primary btn-sm p-3'
                  onClick={handlePurgeCSSCache}
                  disabled={!cssMinify}
                >
                  Purge Cache
                </button>
              </div>
            </div>
          </div>
          {showPurgeConfirmation && (
            <ConfirmDeleteModal
              setIsOpen={setShowPurgeConfirmation}
              title='Are you sure?'
              content='This will purge the complete cache and might degrade the performance for some time'
              deleteFunction={handleConfirmDelete}
            />
          )}
        </div>
      </TabBody>
    </div>
  )
}

export default CSS
