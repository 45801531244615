import React from 'react'
import {TabProps} from './TabProps'
import TabList from './TabList'

const Tabs = ({tabs}: TabProps) => {
  return (
    <div className='pb-5 px-9'>
      <TabList tabs={tabs} />
    </div>
  )
}

export default Tabs
