import {notifyError, notifySuccess} from './NotifyUtils'

const FeatureToggleHandler = async (
  applicationId: string,
  enabled: boolean,
  updateFunction: (id: string, state: {state: boolean}) => Promise<boolean>,
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  successMessage: string,
  errorMessage: string
) => {
  setLoading(true)

  try {
    const newState = {state: !enabled}
    const success = await updateFunction(applicationId ?? '', newState)

    if (success) {
      setEnabled(!enabled)
      notifySuccess(successMessage)
    } else {
      throw new Error('Update request was not successful.')
    }
  } catch (error) {
    const errorMsg = `${errorMessage}: ${(error as Error).message || 'Unknown error occurred'}`
    notifyError(errorMsg)
  } finally {
    setLoading(false)
  }
}

export default FeatureToggleHandler
