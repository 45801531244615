import {useState} from 'react'
import {CloudAccountType} from '../../core/_models'
import {launchExternalServer, launchInternalServer} from '../../core/_requests'
import {notifyError} from '../../../../../utils/NotifyUtils'

export const ServerLaunchButton = ({
  data,
  disabled,
  isBareMetal,
  productId,
  isExternalCloud,
}: {
  data: any
  disabled: boolean
  isBareMetal?: boolean
  productId?: string
  isExternalCloud?: boolean
}) => {
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)

  async function handleExternalServer() {
    setIsSubmitClicked(true)

    try {
      // intialize deployment
      delete data['is_bare_metal']
      if (isBareMetal) {
        delete data['launch_option_id']
      }

      if (data['wordpress_email'].length <= 0) delete data['wordpress_email']

      data['cloud_account_type'] = CloudAccountType.EXTERNAL

      const result = await launchExternalServer(data)

      if (!result) {
        notifyError('external server launch failed, please check your integration and retry!')
      }

      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } catch (error) {
      notifyError(String(error))
      setIsSubmitClicked(false)
    }
  }

  async function handleInternalServer() {
    setIsSubmitClicked(true)

    try {
      const result = await launchInternalServer(data)

      if (!result) {
        notifyError('managed server launch failed, please retry!')
      }

      window.location.reload()
    } catch (error) {
      notifyError(String(error))
      setIsSubmitClicked(false)
    }
  }

  return (
    <>
      <button
        className='btn btn-primary'
        onClick={isExternalCloud ? handleExternalServer : handleInternalServer}
        disabled={disabled}
      >
        Launch{' '}
        {isSubmitClicked && (
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        )}
      </button>
      <p />
    </>
  )
}
