import React, {useState} from 'react'
import TimeChart from './TimeChart'
import {getTTFBResponse} from '../core/_requests'
import SVG from 'react-inlinesvg'
import {TTFBResponseType} from '../core/_models'
import {notifyError} from '../../../../utils/NotifyUtils'

const TTFB: React.FC = () => {
  const [url, setUrl] = useState('')
  const [expandedRow, setExpandedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [invalidUrl, setInvalidUrl] = useState('')
  const [TTFBResponse, setTTFBResponse] = useState<TTFBResponseType[]>([])

  const handleRowClick = (index: any) => {
    setExpandedRow(expandedRow === index ? null : index)
  }

  const handleChangeURL = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value)
    const regex = /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/
    if (regex.test(e.target.value)) {
      setInvalidUrl('')
    } else {
      setInvalidUrl('Please enter a valid url (Ex: https://cloudphant.com/)')
    }
  }

  const fetchTTFB = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    setLoading(true)
    setTTFBResponse([])
    getTTFBResponse(url)
      .then((resp) => {
        setTTFBResponse(resp)
      })
      .catch((err) => {
        notifyError('Failed to fetch time to first byte data. Try again after some time!!')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_newrelic_api_key' className='collapse show'>
        <div className='card-body border-top pb-0 p-9'>
          <form className='input-group mb-1'>
            <input
              type='text'
              className='form-control'
              placeholder='URL'
              aria-label='URL'
              aria-describedby='basic-addon2'
              value={url}
              onChange={handleChangeURL}
            />
            <button
              className='btn btn-primary'
              id='basic-addon2'
              onClick={fetchTTFB}
              type='submit'
              disabled={invalidUrl.length > 0}
            >
              ￫
            </button>
          </form>
          <div className='fv-plugins-message-container mb-4'>
            {invalidUrl && (
              <div className='fv-help-block'>
                <span role='alert'>{invalidUrl}</span>
              </div>
            )}
          </div>
          <div className='table-responsive'>
            <table className='table table-rounded table-striped border gy-7 gs-7 mb-8'>
              <colgroup>
                <col style={{width: '25%'}} />
                <col style={{width: '25%'}} />
                <col style={{width: '25%'}} />
              </colgroup>
              <thead>
                <tr className='fw-semibold fs-6 text-gray-800 border-bottom border-gray-200'>
                  <th>
                    <b>Region</b>
                  </th>
                  <th>
                    <b>TTFB</b>
                  </th>
                  <th>
                    <b>Total Time</b>
                  </th>
                  <th>
                    <b>Status</b>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <tr>
                    <td className='w-100 text-center my-10' colSpan={5}>
                      <span className='spinner-border text-primary' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </span>
                    </td>
                  </tr>
                ) : TTFBResponse && TTFBResponse.length > 0 ? (
                  TTFBResponse.filter((res) => res.ttfb).map((res, index) => {
                    return (
                      <React.Fragment key={res.region}>
                        <tr>
                          <td>
                            <SVG
                              src={`/media/flags/${res.regionInfo.flag}`}
                              className='me-4'
                              style={{borderRadius: '0.300rem'}}
                            />
                            {res.region}
                          </td>
                          <td>{res.ttfb?.toFixed(2)} ms</td>
                          <td>{res.info?.close.toFixed(2)} ms</td>
                          <td>
                            <span
                              className={`badge ${
                                res.status && res.status >= 200 && res.status < 300
                                  ? 'badge-success'
                                  : res.status && res.status >= 300 && res.status < 400
                                  ? 'badge-warning'
                                  : 'badge-danger'
                              }`}
                            >
                              {res.status}
                            </span>
                          </td>
                          <td onClick={() => handleRowClick(index)} style={{cursor: 'pointer'}}>
                            {expandedRow === index ? (
                              <i className='fa-solid fa-arrow-down'></i>
                            ) : (
                              <i className='fa-solid fa-arrow-right'></i>
                            )}
                          </td>
                        </tr>
                        {expandedRow === index && (
                          <tr>
                            <td colSpan={5}>
                              <h3 className='fw-bolder m-0'>URL Load Waterfall</h3>
                              <p className='text-muted'>
                                The chart shows multiple steps involved in loading a url on your
                                browser, we should ideally try to speed up all these steps with
                                various available methods.
                              </p>
                              {res.info && <TimeChart info={res.info} />}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    )
                  })
                ) : (
                  <tr>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TTFB
