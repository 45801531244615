import axios, {AxiosResponse} from 'axios'
import {Subscription} from './_models'
import {ID, PaginationResponse, Response} from '../../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const SUBSCRIPTION_URL = `${API_URL}/v1/payment/subscription`

const renewSubscription = async (serverId: ID): Promise<boolean | undefined> => {
  const response = await axios.post(`${SUBSCRIPTION_URL}/server/${serverId}/renew`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getSubscriptions = (
  query: string
): Promise<PaginationResponse<Subscription[]> | undefined> => {
  return axios
    .get(`${SUBSCRIPTION_URL}?${query}`)
    .then((d: AxiosResponse<Response<PaginationResponse<Subscription[]>>>) => d.data)
    .then((d: Response<PaginationResponse<Subscription[]>>) => d.data)
}

const getSubscriptionByServerId = (id: ID): Promise<Subscription | undefined> => {
  return axios
    .get(`${SUBSCRIPTION_URL}/server?serverId=${id}`)
    .then((d: AxiosResponse<Response<Subscription>>) => d.data)
    .then((d: Response<Subscription>) => d.data)
}

const updateSubscriptionStatusByServerId = (
  serverId: ID,
  status: string
): Promise<boolean | undefined> => {
  return axios
    .post(`${SUBSCRIPTION_URL}/server`, {server_id: serverId, status})
    .then((d: AxiosResponse<Response<boolean>>) => d.data)
    .then((d: Response<boolean>) => d.data)
}

const updateSubscriptionAutoRenewSetting = async (
  serverId: ID,
  autoRenew: boolean
): Promise<boolean> => {
  const response = await axios.post(`${SUBSCRIPTION_URL}/server/${serverId}/auto-renew`, {
    autoRenew,
  })

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

export {
  getSubscriptions,
  getSubscriptionByServerId,
  updateSubscriptionStatusByServerId,
  renewSubscription,
  updateSubscriptionAutoRenewSetting,
}
