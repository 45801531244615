import {useEffect, useState} from 'react'
import TabBody from '../../../../../../utils/TabBody'
import TabHeader from '../../../../../../utils/TabHeader'
import {useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {getPHPConf, updatePHPConf} from '../../../../applications-list/core/_requests'
import helper from '../../../../../../utils/Helper'

const PHPConf = () => {
  const {applicationId} = useParams()

  const [isUpdating, setIsUpdating] = useState(true)
  const [conf, setConf] = useState('')

  const fetchConf = async () => {
    try {
      const response = await getPHPConf(applicationId!)

      setConf(response)
      setIsUpdating(false)
    } catch (error) {
      notifyError('Get PHP conf failed!')
    }
  }

  const updateConf = async () => {
    setIsUpdating(true)

    try {
      await updatePHPConf(applicationId!, conf)
      notifySuccess('PHP conf update successful!')
    } catch (error) {
      notifyError('PHP conf update failed!')
    } finally {
      setIsUpdating(false)
    }
  }

  const handleConfChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    setConf(value)
  }

  useEffect(() => {
    fetchConf()
  }, [])

  return (
    <div className='card mb-3 mb-xl-10 w-100' id='phpconf_tab'>
      <TabHeader heading='PHP Conf' />
      <TabBody>
        <div className='alert alert-danger d-flex align-items-center'>
          <span className='fs-6'>
            {
              'Please restart PHP service after update: Server -> Services -> PHP (version) -> Restart.'
            }
          </span>
        </div>
        <div className='mt-6'>
          <textarea
            className='form-control mb-3'
            placeholder='Loading data...'
            rows={15}
            spellCheck={false}
            style={{minHeight: '150px'}}
            defaultValue={conf}
            onChange={handleConfChange}
          ></textarea>
        </div>
        <div className='mt-6'>
          <button
            type='button'
            className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10`}
            onClick={() => {
              updateConf()
            }}
            disabled={isUpdating || helper.isWhitespaceOnly(conf)}
          >
            <span className='indicator-label'>
              {isUpdating ? 'Please wait . . .' : 'Save Settings'}
            </span>
          </button>
        </div>
      </TabBody>
    </div>
  )
}

export default PHPConf
