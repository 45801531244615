import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import FeatureToggle from '../../../../../common/components/FeatureToggle'
import {
  getFirewallDetails,
  updateFirewallDetails,
  getPhpExecutionDetails,
  updatePhpExecutionDetails,
  getDirectoryBrowsingDetails,
  updateDirectoryBrowsingDetails,
  getLockPluginThemeDetails,
  updateLockPluginThemeDetails,
  getFileEditorDetails,
  updateFileEditorDetails,
  getXmlRpcAccessDetails,
  updateXmlRpcAccessDetails,
  getHotlinkProtectionDetails,
  updateHotlinkProtectionDetails,
  getSensitiveFilesAccessDetails,
  updateSensitiveFilesAccessDetails,
  getLockCoreFilesDetails,
  updateLockCoreFilesDetails,
} from '../../../../applications-list/core/_requests'

const MiscOptions = () => {
  const {applicationId} = useParams()

  // Feature enable states
  const [sensitiveFilesAccessEnabled, setSensitiveFilesAccessEnabled] = useState(false)
  const [firewallEnabled, setFirewallEnabled] = useState(false)
  const [phpExecutionEnabled, setPhpExecutionEnabled] = useState(false)
  const [directoryBrowsingEnabled, setDirectoryBrowsingEnabled] = useState(false)
  const [lockPluginEnabled, setLockPluginEnabled] = useState(false)
  const [fileEditorEnabled, setFileEditorEnabled] = useState(false)
  const [xmlRpcEnabled, setXmlRpcEnabled] = useState(false)
  const [hotlinkProtectionEnabled, setHotlinkProtectionEnabled] = useState(false)
  const [lockCoreFilesEnabled, setLockCoreFilesEnabled] = useState(false)

  // Individual loading states
  const [sensitiveFilesLoading, setSensitiveFilesLoading] = useState(false)
  const [firewallLoading, setFirewallLoading] = useState(false)
  const [phpExecutionLoading, setPhpExecutionLoading] = useState(false)
  const [directoryBrowsingLoading, setDirectoryBrowsingLoading] = useState(false)
  const [lockPluginLoading, setLockPluginLoading] = useState(false)
  const [fileEditorLoading, setFileEditorLoading] = useState(false)
  const [xmlRpcLoading, setXmlRpcLoading] = useState(false)
  const [hotlinkProtectionLoading, setHotlinkProtectionLoading] = useState(false)
  const [lockCoreFilesLoading, setLockCoreFilesLoading] = useState(false)

  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    const fetchData = async () => {
      if (applicationId) {
        try {
          const [
            sensitiveFilesResponse,
            firewallResponse,
            phpExecutionResponse,
            directoryBrowsingResponse,
            lockPluginResponse,
            fileEditorResponse,
            xmlRpcResponse,
            hotlinkProtectionResponse,
            lockCoreFilesResponse,
          ] = await Promise.all([
            getSensitiveFilesAccessDetails(applicationId),
            getFirewallDetails(applicationId),
            getPhpExecutionDetails(applicationId),
            getDirectoryBrowsingDetails(applicationId),
            getLockPluginThemeDetails(applicationId),
            getFileEditorDetails(applicationId),
            getXmlRpcAccessDetails(applicationId),
            getHotlinkProtectionDetails(applicationId),
            getLockCoreFilesDetails(applicationId),
          ])

          setSensitiveFilesAccessEnabled(sensitiveFilesResponse.state)
          setFirewallEnabled(firewallResponse.state)
          setPhpExecutionEnabled(phpExecutionResponse.state)
          setDirectoryBrowsingEnabled(directoryBrowsingResponse.state)
          setLockPluginEnabled(lockPluginResponse.state)
          setFileEditorEnabled(fileEditorResponse.state)
          setXmlRpcEnabled(xmlRpcResponse.state)
          setHotlinkProtectionEnabled(hotlinkProtectionResponse.state)
          setLockCoreFilesEnabled(lockCoreFilesResponse.state)
        } catch (error) {
          const errorMsg = `Failed to fetch details: ${(error as Error).message}`
          setError(errorMsg)
          notifyError(errorMsg)
        }
      }
    }

    fetchData()
  }, [applicationId])

  const handleToggle = async (
    enabled: boolean,
    updateFunction: (id: string, state: {state: boolean}) => Promise<boolean>,
    setEnabled: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    successMessage: string,
    errorMessage: string
  ) => {
    setLoading(true)
    setError(undefined)

    try {
      const newState = {state: !enabled}
      const success = await updateFunction(applicationId ?? '', newState)

      if (success) {
        setEnabled(!enabled)
        notifySuccess(successMessage)
      } else {
        throw new Error('Update request was not successful.')
      }
    } catch (error) {
      const errorMsg = `${errorMessage}: ${(error as Error).message || 'Unknown error occurred'}`
      setError(errorMsg)
      notifyError(errorMsg)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='container-fluid'>
      <TabHeader heading='Miscellaneous Options' />
      <TabBody>
        <div className='alert alert-danger d-flex align-items-center'>
          <span className='fs-6'>
            {
              'Please check your wesbite after enabling these features. These features can break your website.'
            }
          </span>
        </div>
        <div className='d-flex flex-column'>
          <FeatureToggle
            title='Block Sensitive Files Access'
            description='Restrict access to sensitive files (e.g., .htaccess, .git, etc.) that can leak critical information to malicious agents.'
            enabled={sensitiveFilesAccessEnabled}
            onToggle={() =>
              handleToggle(
                sensitiveFilesAccessEnabled,
                updateSensitiveFilesAccessDetails,
                setSensitiveFilesAccessEnabled,
                setSensitiveFilesLoading,
                `Sensitive files access ${
                  sensitiveFilesAccessEnabled ? 'disabled' : 'enabled'
                } successfully!`,
                'Toggling sensitive files access failed!'
              )
            }
            loading={sensitiveFilesLoading}
          />

          <FeatureToggle
            title='Firewall'
            description='Manage firewall to block wide variety of attack patterns including OWASP top attacks.'
            enabled={firewallEnabled}
            onToggle={() =>
              handleToggle(
                firewallEnabled,
                updateFirewallDetails,
                setFirewallEnabled,
                setFirewallLoading,
                `Firewall ${firewallEnabled ? 'disabled' : 'enabled'} successfully!`,
                'Toggling firewall failed!'
              )
            }
            loading={firewallLoading}
          />

          <FeatureToggle
            title='Block PHP Execution in Insecure Directories'
            description='Restrict PHP execution in insecure directories to block malicious agents start multiple forms of attacks like Remote Code Execution (RCE).'
            enabled={phpExecutionEnabled}
            onToggle={() =>
              handleToggle(
                phpExecutionEnabled,
                updatePhpExecutionDetails,
                setPhpExecutionEnabled,
                setPhpExecutionLoading,
                `PHP execution in insecure directories ${
                  phpExecutionEnabled ? 'disabled' : 'enabled'
                } successfully!`,
                'Toggling PHP execution failed!'
              )
            }
            loading={phpExecutionLoading}
          />

          <FeatureToggle
            title='Disable Directory Browsing'
            description='Restrict directory browsing to block users to list contents of WordPress directories.'
            enabled={directoryBrowsingEnabled}
            onToggle={() =>
              handleToggle(
                directoryBrowsingEnabled,
                updateDirectoryBrowsingDetails,
                setDirectoryBrowsingEnabled,
                setDirectoryBrowsingLoading,
                `Directory browsing ${
                  directoryBrowsingEnabled ? 'disabled' : 'enabled'
                } successfully!`,
                'Toggling directory browsing failed!'
              )
            }
            loading={directoryBrowsingLoading}
          />

          <FeatureToggle
            title='Lock Plugins and Themes'
            description='Manage the Lock Plugins and Themes to avoid automatic installations from a malicious script or users. This ensures controlled plugin and theme management.'
            enabled={lockPluginEnabled}
            onToggle={() =>
              handleToggle(
                lockPluginEnabled,
                updateLockPluginThemeDetails,
                setLockPluginEnabled,
                setLockPluginLoading,
                `Lock plugins and themes ${
                  lockPluginEnabled ? 'disabled' : 'enabled'
                } successfully!`,
                'Toggling lock plugin failed!'
              )
            }
            loading={lockPluginLoading}
          />

          <FeatureToggle
            title='Disable Admin Panel File Editor'
            description='Manage the File Editor which blocks unauthorized users to make any changes in WordPress code files from Admin Panel.'
            enabled={fileEditorEnabled}
            onToggle={() =>
              handleToggle(
                fileEditorEnabled,
                updateFileEditorDetails,
                setFileEditorEnabled,
                setFileEditorLoading,
                `File Editor ${fileEditorEnabled ? 'disabled' : 'enabled'} successfully!`,
                'Toggling file editor failed!'
              )
            }
            loading={fileEditorLoading}
          />

          <FeatureToggle
            title='Block XML-RPC Access'
            description='Manage XML-RPC to block exploitation of this API by malicious agents.'
            enabled={xmlRpcEnabled}
            onToggle={() =>
              handleToggle(
                xmlRpcEnabled,
                updateXmlRpcAccessDetails,
                setXmlRpcEnabled,
                setXmlRpcLoading,
                `XML-RPC Access ${xmlRpcEnabled ? 'disabled' : 'enabled'} successfully!`,
                'Toggling XML-RPC access failed!'
              )
            }
            loading={xmlRpcLoading}
          />

          <FeatureToggle
            title='Hotlink Protection'
            description="Manage hotlink protection to prevent resource theft by blocking the use of your website's images on external sites, except for search engines. If someone tries to embed your website's images without permission, the images will be blocked, reducing the load on your server."
            enabled={hotlinkProtectionEnabled}
            onToggle={() =>
              handleToggle(
                hotlinkProtectionEnabled,
                updateHotlinkProtectionDetails,
                setHotlinkProtectionEnabled,
                setHotlinkProtectionLoading,
                `Hotlink Protection ${
                  hotlinkProtectionEnabled ? 'disabled' : 'enabled'
                } successfully!`,
                'Toggling hotlink protection failed!'
              )
            }
            loading={hotlinkProtectionLoading}
          />

          <FeatureToggle
            title='Lock WP Core Files'
            description="Locks core files to avoid changes in case of a hacked website or hacking attacks. This feature will not allow WordPress updates, so turn this off whenever you're updating WordPress."
            enabled={lockCoreFilesEnabled}
            onToggle={() =>
              handleToggle(
                lockCoreFilesEnabled,
                updateLockCoreFilesDetails,
                setLockCoreFilesEnabled,
                setLockCoreFilesLoading,
                `Lock core files ${lockCoreFilesEnabled ? 'disabled' : 'enabled'} successfully!`,
                'Toggling lock core files failed!'
              )
            }
            loading={lockCoreFilesLoading}
          />
        </div>
      </TabBody>
    </div>
  )
}

export default MiscOptions
