import {Response} from '../../../../../_metronic/helpers'
import {Project} from '../../../projects/projects-list/core/_models'
import {Server} from '../../../servers/servers-list/core/_models'

export type Application = {
  application_id?: string
  launch_option_id?: number
  name: string
  server?: Server
  project?: Project
  deployment_status?: ApplicationDeploymentStatus
  internal_domain?: string
  custom_domain?: string
}

export type ApplicationsQueryResponse = Response<Array<Application>>

export type UpdateApplication = {
  application_id?: string
  server_id?: string
  launch_option_id?: number
  name: string
  project_id: string
  wordpress_title?: string
  wordpress_email?: string
  admin_username?: string
  admin_password?: string
}

export type ApplicationMetadata = {
  applicationName: string
  logo: string
  description: string
  serverName: string
  projectName: string
  internalDomain: string
  customDomain: string
  type: ApplicationType
}

export type AccessDetailsDto = {
  applicationId: string
  accessDetails: {
    service: string
    details: {
      [key: string]: any
    }
  }[]
}

export type FTPDetails = {
  ip: string
  username: string
  password: string
}

export enum ApplicationDeploymentStatus {
  INITIATED = 'INITIATED',
  APPLICATION_DEPLOYMENT_SUCCESSFUL = 'APPLICATION_DEPLOYMENT_SUCCESSFUL',
  APPLICATION_DEPLOYMENT_FAILED = 'APPLICATION_DEPLOYMENT_FAILED',
  APPLICATION_DELETED = 'APPLICATION_DELETED',
}

export enum ApplicationIntermediateDeploymentStatus {
  INITIATED = 'INITIATED',
}

export const initialApplication: UpdateApplication = {
  name: '',
  project_id: '',
}

export type ApplicationStaging = {
  staging_state: boolean
  staging_status: string
  staging_application: {
    application_id: string
  }
}

export type ApplicationPageCache = {
  state: boolean
}

export type ApplicationBrowserCache = {
  state: boolean
  ttl: number
}

export type ApplicationObjectCache = {
  state: boolean
}

export type ApplicationEdgeCache = {
  state: boolean
}

export type HTMLMinifyObject = {
  state: boolean
}

export type ApplicationDatabaseOptimizations = {
  [key: string]: {text: string; count: string}
}

export type ApplicationWebPOptimization = {
  state: boolean
  quality: number
  is_metadata: boolean
}

export enum ApplicationType {
  LIVE = 'LIVE',
  STAGING = 'STAGING',
}

export type Backup = {
  id?: string
  name: string
  fileName: string
  type: BackupType
  created_at: Date
  status?: BackupStatus
  restore_status?: RestoreStatus
  location?: BackupLocationType
  storage_provider?: string
}

export enum BackupType {
  ON_DEMAND = 'ON_DEMAND',
  AUTOMATIC = 'AUTOMATIC',
}

export enum BackupStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export type BackupSettings = {
  status: boolean
  scheduled_time: {
    hours: string
    minutes: string
  }
  frequency: number
  retention_period: number
  integrated_storage_id: string
  start_now?: boolean
}

export type StorageProvider = {
  id: string
  name: string
  description: string
  platform: string
  api_key: object
  version: number
}

export enum BackupLocationType {
  LOCAL = 'LOCAL',
  REMOTE = 'REMOTE',
}

export enum RestoreStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum StagingStatus {
  UPDATION_IN_PROGRESS = 'UPDATION_IN_PROGRESS',
  SYNCING_IN_PROGRESS = 'SYNCING_IN_PROGRESS',
  UPDATION_COMPLETED = 'UPDATION_COMPLETED',
  SYNCING_COMPLETED = 'SYNCING_COMPLETED',
}

export enum PurgeType {
  SELECTED = 'SELECTED',
  ALL = 'ALL',
}

export type CreateApplicationCron = {
  name: string
  description?: string
  type: CronSchedule
  command: string
  schedule: string
}

export type UpdateApplicationCron = {
  name: string
  description?: string
  type: CronSchedule
  schedule: string
  command: string
}

export type ApplicationCron = {
  id: string
  name: string
  description?: string
  type: CronSchedule
  schedule: string
  command: string
  created_at: string
}

export enum CronSchedule {
  'Every Minute' = '* * * * *',
  'Every Hour' = '0 * * * *',
  'Every Day (at 12 AM)' = '0 0 * * * ',
  'Every Week (12 AM Sunday)' = '0 0 * * SUN',
  'Every Month (12 AM of 1st)' = '0 0 1 * *',
  'Custom' = 'Custom',
}

export type MalwareScanResult = string[]

export type SensitiveFilesAccessResponse = {
  state: boolean
}

export type UpdateSensitiveFilesAccessRequest = {
  state: boolean
}

export type FirewallResponse = {
  state: boolean
}

export type UpdateFirewallRequest = {
  state: boolean
}

export type PhpExecutionResponse = {
  state: boolean
}

export type UpdatePhpExecutionRequest = {
  state: boolean
}

export type DirectoryBrowsingResponse = {
  state: boolean
}

export type UpdateDirectoryBrowsingRequest = {
  state: boolean
}

export type LockPluginThemeResponse = {
  state: boolean
}

export type UpdateLockPluginThemeRequest = {
  state: boolean
}

export type FileEditorResponse = {
  state: boolean
}

export type UpdateFileEditorRequest = {
  state: boolean
}

export type XmlRpcResponse = {
  state: boolean
}

export type UpdateXmlRpcRequest = {
  state: boolean
}

export type HotlinkProtectionResponse = {
  state: boolean
}

export type UpdateHotlinkProtectionRequest = {
  state: boolean
}
