import React from 'react'

interface FeatureToggleProps {
  title: string
  description: string
  enabled: boolean
  onToggle: () => void
  loading: boolean
}

const FeatureToggle: React.FC<FeatureToggleProps> = ({
  title,
  description,
  enabled,
  onToggle,
  loading,
}) => {
  return (
    <div className='rounded-3 border p-4 mb-4 d-flex'>
      <div className='me-4 flex-grow-1' style={{flex: '1'}}>
        <h5 className='fw-bolder d-flex align-items-center'>{title}</h5>
        <p className='text-muted'>{description}</p>
      </div>
      <div
        className='d-flex border-start flex-row justify-content-center align-items-center'
        style={{flex: '0 0 20%'}}
      >
        <label className='form-check form-switch mt-2'>
          <input
            type='checkbox'
            className='form-check-input'
            checked={enabled}
            onChange={onToggle}
          />
        </label>
        <div style={{width: '20px'}}>
          {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </div>
      </div>
    </div>
  )
}

export default FeatureToggle
