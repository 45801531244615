import axios from 'axios'
import {TTFBResponseType} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const TTFB_URL = `${API_URL}/v1/tool/ttfb`

const getTTFBResponse = async (url: string): Promise<TTFBResponseType[]> => {
  const response = await axios.get(`${TTFB_URL}?url=${url}`)
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

export {getTTFBResponse}
