/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Servers & Apps</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/servers'
        icon='/media/icons/duotune/files/fil020.svg'
        title={intl.formatMessage({id: 'MENU.SERVERS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/applications'
        icon='/media/icons/duotune/abstract/abs027.svg'
        title={intl.formatMessage({id: 'MENU.APPLICATIONS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/projects'
        icon='/media/icons/duotune/files/fil012.svg'
        title={intl.formatMessage({id: 'MENU.PROJECTS'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Integrations</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/integrations/cloud/'
        icon='/media/icons/duotune/files/fil022.svg'
        title={intl.formatMessage({id: 'MENU.MYCLOUD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/integrations/storage'
        icon='/media/icons/duotune/general/gen017.svg'
        title={intl.formatMessage({id: 'MENU.STORAGE'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/integrations/git/'
        icon='/media/icons/duotune/technology/teh003.svg'
        title={intl.formatMessage({id: 'MENU.MYGIT'})}
        fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItem
        to='/integrations/cloudflare/key/'
        icon='/media/icons/duotune/files/fil023.svg'
        title={intl.formatMessage({id: 'MENU.CLOUDFLARE'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/integrations/newrelic/key/'
        icon='/media/icons/duotune/medicine/med001.svg'
        title={intl.formatMessage({id: 'MENU.NEWRELIC'})}
        fontIcon='bi-app-indicator'
      />
      {/*
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>DNS</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/domains'
        icon='/media/icons/duotune/maps/map009.svg'
        title={intl.formatMessage({id: 'MENU.DNS'})}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <SidebarMenuItem
        to='/#'
        icon='/media/icons/duotune/maps/map004.svg'
        title={intl.formatMessage({id: 'MENU.DOMAINREG'})}
        fontIcon='bi-app-indicator'
        disable
        comingSoon
      />
      <SidebarMenuItem
        to='/#'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.AGENCY'})}
        fontIcon='bi-app-indicator'
        disable
        comingSoon
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Addons</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/integrations/cloudflare/list'
        icon='/media/icons/duotune/coding/cod001.svg'
        title={intl.formatMessage({id: 'MENU.CLOUDFLARE'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Tools</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/tools/ttfb'
        icon='/media/icons/duotune/graphs/gra003.svg'
        title={intl.formatMessage({id: 'MENU.TTFB'})}
        fontIcon='bi-app-indicator'
      />
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>User</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/account/overview'
        icon='/media/icons/duotune/communication/com006.svg'
        title={intl.formatMessage({id: 'MENU.PROFILE'})}
        fontIcon='bi-app-indicator'
      /> */}
    </>
  )
}

export {SidebarMenuMain}
