import axios from 'axios'

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401 && window.location.pathname !== '/auth')
      window.location.href = '/auth'

    return Promise.reject(error)
  }
)
